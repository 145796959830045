<template>
  <section>
    <BrunaForm
      ref="rewardOverTierRef"
      v-bind:fields="this.reward_over_tier.fields"
      :card="this.reward_over_tier.card"
    ></BrunaForm>

    <BrunaForm
      ref="comisionesRef"
      v-bind:fields="this.comisiones.fields"
      :card="this.comisiones.card"
    ></BrunaForm>


    <BrunaForm
      ref="toleranciaSatisfaccionDemandaRef"
      v-bind:fields="this.tolerancia_satisfaccion_demanda.fields"
      :card="this.tolerancia_satisfaccion_demanda.card"
    ></BrunaForm>

    <b-card>
      <b-card-body>
        <b-button
          variant="primary"
          class="mt-4 float-right"
          @click="save"
          size="lg"
          >Guardar</b-button
        >
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import
{
  BRow,
  BCol,
  BCard,
  BButton,
  BCardHeader,
  BFormFile,
  BCardTitle,
  BCardSubTitle,
  BCardText,
  BCardBody,
  BFormSelect,
  BIconNodeMinusFill,
  BTabs,
  BSpinner,
  BAlert,
  BTab,
  BLink,
  VBPopover,
  VBTooltip,
  BFormGroup,
  BFormInvalidFeedback

} from "bootstrap-vue";




import BrunaForm from "@/views/brunacomponents/BrunaForm.vue";

import useApiServices from '@/services/useApiServices.js';
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


import axios from '@axios'

export default {
  data()
  {

    let porcentajes = [
      { value: null, text: "Selecciona una opcion" }
    ]

    for (let i = 0; i <= 100; i += 5)
    {
      porcentajes.push({ value: i / 100, text: i + "%" })
    }




    return {



      reward_over_tier: {
        card: {
          title: "Reward over Tier",
          subtitle: "",
          tooltip: ""
        },
        fields: [
          {
            label: "Reward over UB USD/Kg (Tier 1)",
            type: "input",
            id: 'reward_over_ub_usd_kg_tier_1',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Reward over UB USD/Kg (Tier 2)",
            type: "input",
            id: 'reward_over_ub_usd_kg_tier_2',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Reward over UB USD/Kg (Tier 3)",
            type: "input",
            id: 'reward_over_ub_usd_kg_tier_3',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },
          {
            label: "Reward over UB USD/Kg (Tier 4)",
            type: "input",
            id: 'reward_over_ub_usd_kg_tier_4',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },



        ]
      },


      comisiones: {
        card: {
          title: "Comisiones",
          subtitle: "",
          tooltip: ""
        },
        fields: [

          {
            label: "Comisiones %",
            type: "input",
            id: 'porcentaje_comisiones',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },

          {
            label: "Gasto Bodega USD/LB",
            type: "input",
            id: 'gasto_bodega_usd_lb',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },

          {
            label: "Gasto Aduana USD/LB",
            type: "input",
            id: 'gasto_aduana_usd_lb',
            rules: 'required|numeric2',
            tooltip: null,
            format: {
              xs: 3,
              sm: 3,
              md: 3,
              lg: 3,
              xl: 3
            },
            value: null
          },

        ]
      },

      tolerancia_satisfaccion_demanda: {
        card: {
          title: "% Flexibilización de Satisfaccion de Demanda",
          subtitle: "",
          tooltip: ""
        },
        fields: [


          {
            label: "Programas",
            type: "input",
            id: 'tolerancia_demanda_programas',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Spot",
            type: "input",
            id: 'tolerancia_demanda_spot',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


          {
            label: "Forecast",
            type: "input",
            id: 'tolerancia_demanda_forecast',
            rules: 'required|numeric2',
            format: {
              xs: 4,
              sm: 4,
              md: 4,
              lg: 4,
              xl: 4
            },
            value: null
          },


        ]
      },







    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BFormFile,
    BCardTitle,
    BCardSubTitle,
    BCardText,
    BCardBody,
    BFormSelect,
    BTabs,
    VBPopover,
    VBTooltip,
    BTab,
    BLink,
    BAlert,
    BSpinner,
    //GoodTableBasic,
    BFormInvalidFeedback,
    BFormGroup,
    BrunaForm,
    AgGridTable,
    ToastificationContent
  },

  directives: {
    'b-tooltip': VBTooltip,
    "b-popover": VBPopover,
  },

  mounted()
  {
    this.getLastSetting()
      .then((response) =>
      {
        if (response.data)
        {
          let metadata = response.data.meta_data

          let reward_over_tier = metadata.reward_over_tier

          for (let property in reward_over_tier)
          {
            let field = this.findFieldByName(this.reward_over_tier.fields, property)

            if(field){
                field.value = reward_over_tier[property]
            }
          }

          let tolerancia_satisfaccion_demanda = metadata.tolerancia_satisfaccion_demanda

          for (let property in tolerancia_satisfaccion_demanda)
          {
            let field = this.findFieldByName(this.tolerancia_satisfaccion_demanda.fields, property)

            if(field){
                field.value = tolerancia_satisfaccion_demanda[property]

            }
          }

          let comisiones = metadata.comisiones

          for (let property in comisiones)
          {
            let field = this.findFieldByName(this.comisiones.fields, property)

            if(field){
            field.value = comisiones[property]

            }

          }


        }

      })

  },

  methods: {

    findFieldByName(fields, name)
    {

      return fields.find(field => field.id == name)

    },



    getLastSetting()
    {
      return axios.request({
        method: "get",
        url: useApiServices.settingsLast,
        headers: {
          Accept: "application/json",
        },
      })
    },



    formatData()
    {

      return {
        reward_over_tier: this.reward_over_tier.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        comisiones: this.comisiones.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
        tolerancia_satisfaccion_demanda: this.tolerancia_satisfaccion_demanda.fields.reduce((acumulador, field) => { acumulador[field.id] = field.value; return acumulador }, {}),
      }

      


    },

    postData()
    {



      useApiServices.saveAjustesProduccion(this.formatData(), this)
        .then((response) =>
        {

          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Carga Exitosa`,
              icon: "UploadIcon",
              variant: "success",
              html: `Los ajustes se han guardado exitosamente.`,
            },
          });


        })
        .catch((error) =>
        {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Ocurrio un Error`,
              icon: "UploadIcon",
              variant: "danger",
              html: `Ha ocurrido un error al guardar los ajustes</b>.`,
            },
          });

        });





    },

    save()
    {

      let self = this;

      Promise.all([
        this.$refs.rewardOverTierRef.validate(),
        this.$refs.toleranciaSatisfaccionDemandaRef.validate(),
        this.$refs.comisionesRef.validate(),


      ]).then(([rewardOverTierValid, toleranciaSatisfaccionDemandaValid, comisionesValid]) =>
      {

        console.log(self.formatData())

        if (rewardOverTierValid && toleranciaSatisfaccionDemandaValid && comisionesValid)
        {
          this.postData()
        }




      })

    },



  },
};
</script>

<style scoped>
.carga-data {
  margin-top: 4%;
}
</style>

